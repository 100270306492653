<template>
  <div class="pad">
    <b-row :class="'pidkey_' + product.pidkey"><!-- per identificare chiaramente pad/prodotto -->
      <b-col class="pad__column pad__column--first" cols="12" lg="3">
        <div class="pad__image" v-if="statics.urlThumbProdotto !== ''"
             :style="'background-image:url('+ statics.urlThumbProdotto +')'">
          <span class="pad__image-corner"></span>
        </div>
        <div class="pad__image pad__image--placeholder" v-else
             :style="'background-image:url(https://via.placeholder.com/550x280.png/efefef/?text=Immagine+non+disponibile)'">
        </div>
      </b-col>
      <b-col class="pad__column pad__column--second" cols="12" lg="9">
        <div class="pad__wrap">
          <b-row>
            <b-col class="pad__wrap-element pad__wrap-column--first" cols="12" lg="8" xl="9">
              <div class="pad__head">
                <div class="pad__head-title">

                  <div class="pad__head-star" v-if="contains_numb(product.rating)">
                    <b-form-rating
                        no-border inline readonly
                        size="sm"
                        :value="product.rating.match(/\d+/)[0]"
                    />
                  </div>

                  <h2 class="pad__head-name">{{statics.nomeProdotto}}</h2>
                  <img class="pad__head-brand" :src="'assets/images/alpitour/liveat-brands/'+brand.logo" :alt="brand.name" />
                </div>
                <p class="pad__head-locality"> {{statics.city | capitalize}}</p>
                <p class="pad__head-abstract">{{statics.abstractProdotto}}</p>
              </div>
              <div class="pad__push">
                <vb-icon class="pad__push-btn" v-b-popover.hover.top="'Trasferimenti Inclusi'" :name="'bus'" :color="sass('dark')" :size="16" />
                <vb-icon class="pad__push-btn" v-b-popover.hover.top.html="'<span class=\'text-capitalize\'>'+ product.room.toLowerCase() + '</span>'" :name="'doublebed'" :color="sass('dark')" :size="16" />
                <vb-icon class="pad__push-btn" v-b-popover.hover.top.html="'<span class=\'text-capitalize\'>'+ product.accommodation.toLowerCase() + '</span>'" :name="'cutlery'" :color="sass('dark')" :size="16" />
                <!-- Per ora l'assicurazione è da nascondere -->
                <!--<vb-icon class="pad__push-btn" v-b-popover.hover.top="'Assicurazione'" :name="'shield'" :color="sass('dark')" :size="16" />-->
              </div>
              <div class="pad__info">
                <div class="pad__info-item">
                  <vb-icon class="pad__info-icon" :name="'air_start'" :color="sass('primary')" :size="15" />
                  <span class="pad__info-title">
                    da
                    {{product.departureAirportDescription | capitalize}} {{product.departureAirportCode}}
                  </span>
                </div>
                <div class="pad__info-item">
                  <vb-icon class="pad__info-icon" :name="'calendar'" :color="sass('primary')" :size="15" />
                  <span class="pad__info-title">
                    {{parse_date(product.departureDate).day_number}} {{parse_date(product.departureDate).month_string.slice(0, 3)}}
                    -
                    {{parse_date(product.returnDate).day_number}} {{parse_date(product.returnDate).month_string.slice(0, 3)}}
                    {{parse_date(product.returnDate).year}}
                  </span>
                </div>
                <div class="pad__info-item">
                  <vb-icon class="pad__info-icon" :name="'moon'" :color="sass('primary')" :size="15" />
                  <span class="pad__info-title">
                    {{product.nights}} notti
                  </span>
                </div>
                <div class="pad__info-item">
                  <vb-icon class="pad__info-icon" :name="'occupants'" :color="sass('primary')" :size="15" />
                  <span class="pad__info-title">
                    {{occupancy[0].adults}} adulti
                    <span v-if="occupancy[0].children.length">
                      + {{occupancy[0].children.length}} {{occupancy[0].children.length > 1 ? 'bambini' : 'bambino'}}
                    </span>
                  </span>
                </div>
              </div>
            </b-col>
            <b-col class="pad__wrap-element pad__wrap-column--second" cols="12" lg="4" xl="3">
              <div class="pad__footer">
                <div class="pad__footer-element">
                  <div class="pad__footer-price">
                    da
                    <vb-icon class="pad__footer-icon" :name="'euro'" :color="sass(availability.is_known && availability.total < markup(product.price).display_price ? 'secondary' : 'info')" :size="15" />
                    <span class="pad__footer-value" :class="availability.is_known && availability.total < markup(product.price).display_price ? 'text-secondary' : ''">
                      {{markup(product.price).display_price}}
                    </span>
                  </div>
                  <span class="pad__footer-divide">(€ {{ round(divide(markup(product.price).display_price, (occupancy[0].adults + occupancy[0].children.length)), 2) }}/pers.)</span>
                </div>
                <div class="pad__footer-element">
                  <a href="javascript:window.zE.activate()" class="pad__footer-nocredit"
                     v-if="availability.is_known && availability.total < markup(product.price).display_price"
                     title="Contattaci">
                    <span v-html="'Credito non sufficiente?'" />
                    <vb-icon
                        class="vb-pad__nocredit-icon ml-1"
                        :name="'vb-chat'"
                        :color="sass('info')"
                        :size="18"
                    />
                  </a>
                  <custom-link
                      :before_navigation="() => {
                        update_store({
                          pid: product.pid,
                          departure_date: product.departureDate,
                          days: product.days
                        })
                      }"
                      :route_name="'detail'"
                      :css_class="'d-block'"
                  >
                    <template #content>
                      <vb-button
                          class="pad__footer-btn shadow"
                          :vb_options="{
                            text: 'Scopri!',
                            variant: 'info'
                          }"
                      />
                    </template>
                  </custom-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { parse_date } from '@utilities/commons'
import {markup} from '@alpitour/utilities'
import { divide, round } from 'mathjs'

export default {
  name: "pad",
  components: {
  },
  props: {
    product: Object,
    statics: Object,
    occupancy: Array,
    keyds: Object,
    brand: Object,
  },
  created() {
    this.parse_date = parse_date
  },
  computed: {
    availability() {
      return this.get_availability()
    }
  },
  watch: {},
  data() {
    return {
      price_popover: "Il prezzo che vedi è già comprensivo delle riduzioni o eventuali gratuità previste per i bambini. * I trasferimenti non sono inclusi nei pacchetti per gli Stati Uniti. Per maggiori informazioni contattare l'assistenza.",
      insurance_popover: "Per i dettagli vedi le Condizioni d'uso"
      // testo credito non sufficiente nocredit_popover: "Il tuo credito non è sufficiente per prenotare questa vacanza. <a href=\"javascript:window.zE.activate()\">Contattaci!</a>",
    };
  },
  methods: {
    markup,
    divide,
    round,
    contains_numb(str) {
      return /\d/.test(str);
    },
    update_store(params) {
      this.$store.commit('liveat/pid', params.pid)
      this.$store.commit('liveat/days', params.days)
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">

  .pad {
    position: relative;

    @include media-breakpoint-up(lg) {
      height: 204px;
      overflow: hidden;
    }

    &>.row {
      height: 100%;
      padding-bottom: 22px;
    }

    &__column {
      &--second {
        position: static !important;
      }
    }

    &__image {
      min-height: 300px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 5px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 102%, 85% 84%, 0 84%);
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, 101% 0, 91% 23%, 91% 100%, 0% 101%);
        min-height: 100%;
      }

      // trick angolo smussato
      &-corner {
        width: 30px;
        height: 30px;
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 16%;
        z-index: 1;
        transform: scaleX(-1);

        @include media-breakpoint-up(lg) {
          left: auto;
          right: 9%;
          bottom: 0;
          transform: scaleX(1);
        }

        &:before {
          content: "";
          width: 200%;
          height: 200%;
          position: absolute;
          border-radius: 7px;
          bottom: 0;
          right: 0;
          box-shadow: 50px 50px 0 0 $vb-body-bg;
        }
      }
    }

    &__wrap {
      background-color: $white;
      margin-top: -40px;
      padding: 15px 20px;
      border-radius: 0 5px 5px;
      height: 100%;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: -50px;
        padding: 20px 60px 20px 20px;
      }

      &-column {
        &--first {
          position: static !important;
        }
      }
    }

    &__head {

      &-title {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          position: relative;
        }
      }
      
      &-star {
        .b-rating {
          .flex-grow-1 {
            flex-grow: unset !important;
          }
        }
        @include media-breakpoint-up(lg) {
          display: flex;
          order: 2;
        }
      }

      &-name {
        font-size: $font-size-base;
        font-weight: $vb-font-weight-semibold;
        margin-bottom: 0;
        margin-top: 5px;

        @include media-breakpoint-up(lg) {
          order: 1;
          margin-top: 0;
          margin-right: 15px;
        }
      }

      &-brand {
        background-color: $white;
        border-radius: 30px;
        width: 110px;
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        padding: 7px;

        @include media-breakpoint-up(lg) {
          top: -5px;
          right: 0;
        }
      }

      &-locality {
        color: $gray-500;

        @include media-breakpoint-up(lg) {
          margin-bottom: 10px;
        }
      }

      &-abstract {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
          font-size: $font-size-sm;
        }
      }
    }

    &__push{
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 22px;
        background-color: $dark;
        padding: 0 12px;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
      }

      &-btn {
        background-color: $vb-body-bg;
        border-radius: 50%;
        padding: 10px;
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          background-color: transparent;
          border-radius: 0;
          padding: 0 0 15px;
          margin-right: 0;
          margin-bottom: 15px;
          border-bottom: 1px solid $gray-600;
        }

        &:last-child {
          margin-right: 0;

          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
          }
        }

        // cambio colore icona desktop
        path {
          @include media-breakpoint-up(lg) {
            fill: $white;
          }
        }
      }
    }

    &__element {
      border-top: 1px solid $gray-200;
      padding-top: 10px;
      margin-top: 10px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      padding: 5px 0;
      border-width: 1px 0;
      border-style: solid;
      border-color: $gray-300;

      @include media-breakpoint-up(lg) {
        padding: 10px 0 0;
        margin-bottom: 0;
        border-bottom: 0;
      }

      &-item {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        padding-top: 3px;
        padding-bottom: 3px;

        @include media-breakpoint-up(lg) {
          flex: 0 1 auto;
          margin-right: 25px;
          align-items: flex-start;
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(even) {
          padding-left: 10px;

          @include media-breakpoint-up(lg) {
            padding-left: 0;
          }
        }
      }

      &-icon {
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          margin-right: 5px;
        }
      }

      &-title {
        font-size: $font-size-sm;
        font-weight: $vb-font-weight-semibold;
      }
    }

    &__footer {

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      &-price {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
      }

      &-icon {
        margin-left: 5px;
      }

      &-value {
        font-size: $font-size-lg;
        color: $info;
        margin-left: 5px;
        font-weight: $vb-font-weight-semibold;

        @include media-breakpoint-up(lg) {
          font-size: 1.438rem;
        }
      }

      &-divide {
        font-size: $font-size-sm;
        font-weight: $vb-font-weight-semibold;
        text-align: center;
        display: block;
      }

      &-nocredit {
        color: $secondary;
        font-size: $font-size-lg;
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-weight: $vb-font-weight-semibold;
        padding-top: .625rem;
        transition: auto;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-sm;
        }
      }

      &-btn {
        margin-top: 20px;
        display: block;
        width: 100%;

        @include media-breakpoint-up(lg) {
          display: inline-block;
          width: 200px;
          margin-top: 10px;
        }
      }
    }
  }

</style>