<template>
  <div>
    <div v-for="(product, product_index) in product_detail" :key="_uid+'rating'+product_index">
      <section class="section rating"
               v-if="statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.perche_scegliere_questo_tour"
      >
        <b-row>
          <b-col lg="2" class="rating__element"
                 v-for="(rate, rate_index) in statics_detail[product.baseProdotto.pid].schedaProdotto.caratteristiche_prodotto.known.perche_scegliere_questo_tour"
                 v-bind:key="_uid+rate_index"
          >
            <div class="rating__item">
              <div class="rating__item-header">
                <div class="rating__item-circle" :style="'background-image: conic-gradient(' + sass('secondary') + ' ' + rate.valore_caratteristica +'%, ' + sass('light')+ ' 0)'">
                  <span class="rating__item-circle_inner h2">{{rate.valore_caratteristica}}%</span>
                </div>
              </div>
              <div class="rating__item-body">
                <h4 class="rating__item-title vb-heading">{{rate.nome}}</h4>
                <p class="rating__item-text">{{rate.note_caratteristica}}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>


export default {
  name: 'rating-tour',

  props: {
    statics_detail: Object,
    product_detail: Array,
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">

  .rating {
    @extend %section_margin;

    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*3;
    }

    &__element {
      margin-bottom: $grid-gutter-width/3;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    &__item {
      min-height: 100%;
      border: none;
      background-color: $white;
      display: flex;
      flex-direction: column;
      border-radius: $grid-gutter-width/6;

      &-header {
        text-align: center;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        padding-top: $grid-gutter-width/3;
        padding-bottom: $grid-gutter-width/2;

        @include media-breakpoint-up(lg) {
          margin-top: -3.75rem;
          padding-top: 0;
        }
      }

      &-circle {
        $size: 130px;
        position: relative;
        width: $size;
        height: $size;
        border-radius: 50%;
        overflow: hidden;

        &_inner {
          $size: 115px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: $size;
          height: $size;
          background: $white;
          border-radius: 50%;
          margin-bottom: 0;
        }
      }

      &-title {
        text-align: center;
      }

      &-body {
        padding: 0 1.25rem;
      }

      &-text {
        font-size: $font-size-sm;
      }
    }
  }

</style>