<template>
  <div class="my-alpitour">
    <b-row no-gutters>
      <b-col md="3" class="my-alpitour__logo">
        <img :src="'/assets/images/alpitour/liveat-brands/'+get_brand('AWP').logo">
      </b-col>
      <b-col md="6" xl="5" class="my-alpitour__text">
        <div class="h1">
          my Alpitour World
        </div>
        <div class="h4 mb-4">
          l'app che ti assiste durante il viaggio
        </div>
        <ul>
          <li>
            Una volta a destinazione potrai contattare l'assistenza in loco o il servizio di assistenza Amico con un click
          </li>
          <li>
            Potrai contattare direttamente l'assistenza e la centrale operativa della tua assicurazione Europ Assistance
          </li>
          <li>
            Potrai accedere ai documenti di viaggio ed eseguire i checkin più velocemente
          </li>
          <li>
            Avrai un quadro pratico e veloce dei servizi, delle attività, dell'intrattenimento inclusi nel pacchetto e disponibili nella struttura prenotata
          </li>
        </ul>
        
        <div class="my-alpitour__links">
          <a href="https://apps.apple.com/it/app/myalpitourworld/id1107036455" target="_blank">
            <vb-icon :name="'store_apple'" :size="28" :color="sass('info')" /> scarica la versione apple
          </a>
          
          <a href="https://play.google.com/store/apps/details?id=com.ionicframework.alpitour505171&gl=IT&pli=1" target="_blank">
            <vb-icon :name="'store_android'" :size="28" :color="sass('info')" /> scarica la versione android
          </a>
        </div>

      </b-col>
      <b-col md="3" offset-xl="1" class="my-alpitour__image">
        <img :src="'/assets/images/alpitour/statics/my-alpitour.png'">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { get_brand } from '@alpitour/resources'
export default {
  name: 'pratica',

  props: {
  },

  components: {
  },

  data() {
    return {
    }
  },

  created() {
    this.get_brand = get_brand
  },

  mounted() {
  },

  methods: {
  }
}
</script>

<style lang="scss">
.my-alpitour {
  &>* {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: $border-radius-lg;
    padding-top:$grid-gutter-width;
    padding-bottom:$grid-gutter-width;
  }
  &__logo {
    text-align: center;
    img,svg {
      margin:0 auto;
      width: 260px;
      max-width: 100%;
    }
  }
  &__text {

  }
  &__image {
    text-align:right;
    max-width: 100%;
    padding-right: 0;
  }

  &__links {
    margin-top: 1rem;
    display: flex;
    //flex-direction: column;
    flex-wrap: wrap;
    a {
      display: flex;
      align-items: center;
      padding:0.35rem 0;
      white-space: nowrap;
      svg {
        margin-right: 8px;
      }
      margin-right: 1rem;
    }
  }
}
</style>