<template>

  <div class="vb-detail">

    <div v-for="(product, product_index) in product_detail" :key="_uid+'vb-detail'+product_index">
      <div v-if="product.soluzioni_parsed.length === 0">
        <b-alert
            show
            variant="warning"
        >
          Attenzione, non ci sono soluzioni disponibili per questo pacchetto. Torna indietro o prova a cambiare i criteri di ricerca.
        </b-alert>
        <br><br>
      </div>

      <div v-if="is_mobile()">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <router-link :to="{name: 'results'}">
            <span class="vb-detail__back">indietro</span>
          </router-link>
          <div class="vb-detail__head-brand">
            <img :src="'assets/images/alpitour/liveat-brands/'+get_brand(product_brand).logo" :alt="get_brand(product_brand).name" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="vb-detail__head-city">
            <vb-icon
                class="mr-1"
                :name="'pin'"
                :size=14
                :color="sass('gray-500')"
            /><span class="text-capitalize">{{product.baseProdotto.localita.toLowerCase().replace('/', '-')}}</span>
          </div>

          <div class="vb-detail__head-star" v-if="product.editoriale.categoriaUff !== 'NC'">
            <b-form-rating
                no-border inline readonly
                variant="secondary"
                :value="product.editoriale.categoriaUff.slice(0, 1)"
            />
          </div>
        </div>
        <div class="vb-detail__head mb-4">
          <h1>{{product.baseProdotto.nomeHotel}}</h1>
          <div class="vb-detail__head-rate" v-if="product.editoriale.valutazioneAlpitour !== 'NC'">
            <span class="mr-2">Valutazione Alpitour</span>
            <div>
              <b-form-rating
                  no-border inline readonly
                  variant="secondary"
                  icon-empty="circle"
                  icon-half="circle-half"
                  icon-full="circle-fill"
                  size="sm"
                  :color="sass('gray-600')"
                  :value="product.editoriale.valutazioneAlpitour.replace('/', '.5')"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <router-link :to="{name: 'results'}">
          <span class="vb-detail__back">indietro</span>
        </router-link>
        <div class="mt-3 d-flex align-items-start">
          <h1 class="mr-2">{{product.baseProdotto.nomeHotel}}</h1>
          <div class="vb-detail__head-star" v-if="product.editoriale.categoriaUff !== 'NC'">
            <b-form-rating
                no-border inline readonly
                variant="secondary"
                :value="product.editoriale.categoriaUff.slice(0, 1)"
            />
          </div>
        </div>
        <div class="vb-detail__head mb-4">
          <div class="d-flex align-items-center">
            <div class="vb-detail__head-city">
              <vb-icon
                  class="mr-1"
                  :name="'pin'"
                  :size=14
                  :color="sass('gray-500')"
              /><span class="text-capitalize">{{product.baseProdotto.localita.toLowerCase().replace('/', '-')}}</span>
            </div>
            <div class="vb-detail__head-rate" v-if="product.editoriale.valutazioneAlpitour !== 'NC'">
              <img class="mr-2" src="assets/images/alpitour/liveat-brands/Alpitour-World_logo_grigio.png" />
              <span class="mr-2">Valutazione Alpitour</span>
              <div>
                <b-form-rating
                    no-border inline readonly
                    variant="secondary"
                    icon-empty="circle"
                    icon-half="circle-half"
                    icon-full="circle-fill"
                    size="sm"
                    :color="sass('gray-600')"
                    :value="product.editoriale.valutazioneAlpitour.replace('/', '.5')"
                />
              </div>
            </div>
          </div>
          <div class="vb-detail__head-brand">
            <img :src="'assets/images/alpitour/liveat-brands/'+get_brand(product_brand).logo" :alt="get_brand(product_brand).name" />
          </div>
        </div>
      </div>
    </div>

    <div v-for="(block,key) in detail_blocks" v-bind:key="_uid+key">
      <component
          v-if="block.show"
          v-bind:key="_uid+block+key"
          :is="block.key"
          :statics_detail="statics_detail"
          :product_detail="product_detail"
          :supplement="supplement"
          @selected-room="handle_selected_room"
      />
    </div>

    <!--    RECAP PACCHETTO -->
    <card-recap class="section" v-if="Object.keys(selected_room).length !== 0" :selected_room="selected_room" />

    <div v-for="(product, product_index) in product_detail" :key="_uid+'vb-detail-button'+product_index">
      <div class="d-flex justify-content-center justify-content-lg-end" v-if="Object.keys(selected_room).length === 0">
        <vb-button
            v-if="product.soluzioni_parsed.length > 0"
            :vb_options="{
                  text: 'Scegli una camera per continuare',
                  variant: 'info',
                  size: 'lg',
                  disabled: true,
                  icon_disabled: 'lock'
        }"/>
      </div>
      <div class="d-flex justify-content-center justify-content-lg-end" v-else>
      <custom-link
          :route_name="'quote'"
          :wrap="'a'"
      >
        <template #content>
          <vb-button
              :vb_options="{
                  text: 'Continua',
                  variant: 'info',
                  size: 'lg',
          }" />
        </template>
      </custom-link>
    </div>
    </div>
  </div>
</template>

<script>

import {is_mobile} from '@utilities/commons'
import {get_brand} from '@alpitour/resources'

import Carousel from '@alpitour/components/blocks/detail/carousel.vue'
import PanoramicHotel from '@alpitour/components/blocks/detail/panoramic-hotel.vue'
import PanoramicTour from '@alpitour/components/blocks/detail/panoramic-tour.vue'
import Information from '@alpitour/components/blocks/detail/information.vue'
import RatingTour from '@alpitour/components/blocks/detail/rating-tour.vue'
import Program from '@alpitour/components/blocks/detail/program.vue'
import ServicesInsurance from '@alpitour/components/blocks/detail/assicurazione-medico-bagaglio.vue'
import ServicesPopular from '@alpitour/components/blocks/detail/services-popular.vue'
import ServicesAll from '@alpitour/components/blocks/detail/services-all.vue'
import Faq from '@alpitour/components/blocks/detail/faq.vue'
import Promo from '@alpitour/components/blocks/detail/promo.vue'
import Solutions from '@alpitour/components/blocks/detail/solutions.vue'
import CardRecap from '@alpitour/components/blocks/card-recap.vue'


export default {
  name: "detail-block",

  components: {
    'carousel': Carousel,
    'panoramic-hotel': PanoramicHotel,
    'panoramic-tour': PanoramicTour,
    'information': Information,
    'rating-tour': RatingTour,
    'program': Program,
    'services-insurance': ServicesInsurance,
    'services-popular': ServicesPopular,
    'services-all': ServicesAll,
    'faq': Faq,
    'promo': Promo,
    'solutions': Solutions,
    'card-recap': CardRecap,
  },

  props: {
    product_detail: Array,
    statics_detail: Object,
    supplement: Array,
    product_brand: String,
  },

  created() {
    this.is_mobile = is_mobile
    this.get_brand = get_brand
  },

  computed: {
  },

  data() {
    return {
      detail_blocks: [],
      availabilities: this.$store.state.liveat.availabilities,
      selected_room: {}
    }
  },

  mounted() {
    this.detail_blocks = [
      {
        key: "carousel",
        show: true
      },
      {
        key: "panoramic-hotel",
        show: true
      },
      {
        key: "panoramic-tour",
        show: true
      },
      {
        key: "information",
        show: true
      },
      {
        key: "solutions",
        show: true
      },
      {
        key: "rating-tour",
        show: true
      },
      {
        key: "program",
        show: true
      },
      {
        key: "services-insurance",
        show: true
      },
      {
        key: "services-popular",
        show: true
      },
      {
        key: "services-all",
        show: true
      },
      {
        key: "faq",
        show: true
      },
      {
        key: "promo",
        show: true
      }
    ]
  },

  methods: {
    handle_selected_room(room) {
      this.selected_room = room;
    },
  },
}
</script>

<style lang="scss">

  .vb-detail {
    margin-bottom: $grid-gutter-width*4;

    // Badge no-passaporto
    &__badge {
      cursor: default;

      &--passport {
        display: flex;
        align-items: center;
        margin-left: .625rem;
      }

      &-icon {
        margin-left: .188rem;
      }
    }

    // Back Link
    &__back {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $info;

      &:before {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-top: 1px solid $info;
        border-left: 1px solid $info;
        transform: rotate(-45deg);
        margin-right: 3px;
      }
    }

    // Titolo Struttura
    &__head {

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .vb-icon {
        &--heart {
          position: absolute;
          right: 0;
          top: 10px;
          cursor: pointer;
          fill: transparent;
          stroke: $gray-600;

          &:hover {
            stroke: $red;
            fill: $red;
          }
        }
      }

      &-star {
        display: flex;
      }

      &-city {
        display: flex;
        align-items: center;
        color: $gray-600;
        margin-right: $grid-gutter-width/2;
      }

      &-rate {
        display: flex;
        align-items: center;
        color: $gray-600;
        white-space: nowrap;

        img {
          width: 40px;
        }
      }

      &-brand {

        img,svg {
          height: 16px;
          width: auto;

          @include media-breakpoint-up(lg) {
            height: 20px;
          }

        }
      }
    }
  }

</style>