<template>
  <b-col md="6">
    <b-row>
      <b-col md="6">
        <div class="h6 site-footer__title">
          Viaggia con {{ get_config().site.brand }}
        </div>
        <ul class="site-footer__links">
          <li class="site-footer__link">
            <router-link :to="{ name: 'viaggia-con-noi', hash: '#prenota-un-viaggio' }">
              Prenota un viaggio
            </router-link>
          </li>
          <li class="site-footer__link">
            <router-link :to="{ name: 'viaggia-con-noi', hash: '#domande-frequenti' }">
              Domande frequenti
            </router-link>
          </li>
          <li class="site-footer__link">
            <router-link :to="{ name: 'viaggia-con-noi', hash: '#contattaci' }">
              Contattaci
            </router-link>
          </li>
        </ul>
      </b-col>
      <b-col md="6">
        <div class="h6 site-footer__title">
          Chi siamo
        </div>
        <ul class="site-footer__links">
          <li class="site-footer__link">
            <router-link :to="{ name: 'about' }">VadoBay di Salabam Solutions</router-link>
          </li>
          <li class="site-footer__link">
            <a href="https://www.salabamsolutions.com" target="_blank">Salabam Solutions</a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>

export default {
  name: "site-footer",
  components: {
  },
  created() {
  },
  computed: {},
  watch: {},
  data() {
    return {
    }
  },
  methods: {},
  mounted() { },
};
</script>

<style lang="scss">

</style>