<template>

  <b-card
      no-body
      class="vb-card-recap"
  >
    <b-card-header class="vb-card-recap__header text text--lg text-primary semibold">
      <div class="vb-card-recap__header-item">
        <vb-icon
            :name="'occupants'"
            :size=20
            :color="sass('primary')"
            class="mr-1"
        />
        {{selected_room.quote_payload.paxA + (selected_room.quote_payload.paxA > 1 ? ' adulti' : ' adulto')}}
        <span class="ml-1" v-if="selected_room.quote_payload.paxC > 0">+ {{selected_room.quote_payload.paxC + (selected_room.quote_payload.paxC > 1 ? ' bambini ' : ' bambino')}}</span>
        <span class="ml-1" v-if="selected_room.quote_payload.paxI > 0">+ {{selected_room.quote_payload.paxI + (selected_room.quote_payload.paxI > 1 ? ' infanti' : ' infante')}}</span>
<!--        {{Number(selected_room.quote_payload.paxA) + (Number(selected_room.quote_payload.paxC) + Number(selected_room.quote_payload.paxI))}} ospiti-->
      </div>
      <div class="vb-card-recap__header-item">
        <vb-icon
            :name="'calendar'"
            :size=20
            :color="sass('primary')"
            class="mr-1"
        />
        {{parse_date(selected_room.data_andata).day_number}} {{parse_date(selected_room.data_andata).month_string.slice(0, 3)}} {{parse_date(selected_room.data_andata).year}} -
        {{parse_date(selected_room.data_ritorno).day_number}} {{parse_date(selected_room.data_ritorno).month_string.slice(0, 3)}} {{parse_date(selected_room.data_ritorno).year}}
        ({{selected_room.quote_payload.days}} notti)
      </div>
      <div class="vb-card-recap__header-item">
        <span class="d-flex align-items-center">
          <vb-icon
            :name="'airplane'"
            :size=20
            :color="sass('primary')"
            class="mr-1"
          />
          da&nbsp;<span class="text-capitalize">{{selected_room.aeroporto_partenza_andata.toLowerCase()}}</span>&nbsp;({{selected_room.aeroporto_partenza_andata_code}})
        </span>
        &nbsp;
        <span>
          a&nbsp;<span class="text-capitalize">{{selected_room.aeroporto_arrivo_andata.toLowerCase()}}</span>&nbsp;({{selected_room.aeroporto_arrivo_andata_code}})
        </span>
      </div>
    </b-card-header>
    <b-card-body>
      <b-card-title class="vb-card-recap__title">
        <span class="vb-card-recap__title-item h5 regular">
          <vb-icon
              :name="'bed'"
              :size=17
              :color="sass('primary')"
              class="mr-1"
          />{{selected_room.quote_payload.roomDescription.toLowerCase()}}
        </span>
        <span class="vb-card-recap__title-item h5 regular">
          <vb-icon
              :name="'cutlery'"
              :size=17
              :color="sass('primary')"
              class="mr-1"
          />{{selected_room.quote_payload.roomTrattDescription.toLowerCase()}}
        </span>
        <span class="vb-card-recap__title-item h5 regular">
          <vb-icon
              :name="'bus'"
              :size=17
              :color="sass('primary')"
              class="mr-1"
          />Trasferimenti inclusi *
        </span>
<!--        <span class="vb-card-recap__title-item h5 regular" v-b-modal="'insurance'">
          <vb-icon
              :name="'shield'"
              :size=17
              :color="sass('primary')"
              class="mr-1"
          />Assicurazione
          <vb-icon
              class="ml-1"
              :name="'info'"
              :color="sass('gray-500')"
              :size="14"
          />
        </span>-->

        <vb-modal :vb_options="{
          id: 'insurance',
          size: 'lg',
          title: 'I pacchetti viaggio includono',
          hide_footer: true,
        }">
         <services-insurance class="p-0 mt-0" />
        </vb-modal>

      </b-card-title>
      <b-card-text class="vb-card-recap__price">
        <span>
          Il prezzo che vedi è già comprensivo delle riduzioni o eventuali gratuità previste per i bambini. * I trasferimenti non sono inclusi nei pacchetti per gli Stati Uniti. Per maggiori informazioni contattare l'assistenza.
        </span>
        <span>
          <span class="text--lg text-primary text-right semibold" :class="availability.is_known && availability.total < markup(selected_room.quote_payload.totAmount).display_price ? 'text-dark' : ''">
            € {{markup(selected_room.quote_payload.totAmount).display_price}}
            <em class="text--sm regular">
              (€ {{round(divide(markup(selected_room.quote_payload.totAmount).display_price, (Number(selected_room.quote_payload.paxA) + Number(selected_room.quote_payload.paxC) + Number(selected_room.quote_payload.paxI))), 2)}}/pers.)
            </em>
          </span>

          <a href="javascript:window.zE.activate()" v-if="availability.is_known && availability.total < markup(selected_room.quote_payload.totAmount).display_price" class="vb-card-recap__nocredit"
             title="Contattaci">
            <span v-html="'Credito non sufficiente?'" />
            <vb-icon
                class="vb-card-recap__nocredit-icon ml-1"
                :name="'vb-chat'"
                :color="sass('info')"
                :size="18"
            />
          </a>
        </span>
      </b-card-text>
    </b-card-body>
  </b-card>

</template>

<script>
import { parse_date } from '@utilities/commons'
import { markup } from '@alpitour/utilities'
import { round, divide } from 'mathjs'
import ServicesInsurance from '@alpitour/components/blocks/detail/assicurazione-medico-bagaglio.vue'
export default {
  name: 'card-recap',

  props: {
    selected_room: Object
  },

  components: {
    'services-insurance': ServicesInsurance,
  },

  data() {
    return {
    }
  },

  created() {
    this.parse_date = parse_date
  },

  computed: {
    availability() {
      return this.get_availability()
    }
  },

  mounted() {
  },

  methods: {
    markup,
    round,
    divide,
  }
}
</script>

<style lang="scss">

%vb-card-recap__item {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  margin-bottom: .625rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    flex-basis: auto;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }
}

.vb-card-recap {

  &__header {
    display: flex;
    flex-wrap: wrap;

    &-item {
      @extend %vb-card-recap__item;

      @at-root .card-header & {
        &:last-child {
          display: block;
          
          @include media-breakpoint-up(sm) {
            display: flex;
          }
        }
      }
    }
  }

  &__title {
    text-transform: capitalize;
    display: flex;
    flex-wrap: wrap;

    &-item {
      line-height: 2rem;
      @extend %vb-card-recap__item;
    }
  }

  &__price {

    span {
      display: block;

      &:last-child {
        margin-top: .625rem;

        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__nocredit {
    color: $secondary;
    font-size: $font-size-lg;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    font-weight: $vb-font-weight-semibold;
    padding: .625rem 0;

    @include media-breakpoint-up(xl) {
      font-size: $font-size-sm;
      padding: 0;
      margin-left: $grid-gutter-width/3;

    }
  }
}

</style>
